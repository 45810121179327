import React from 'react';

import { IModelTemplateData } from '@/templates/model-2024';

type Props = {
  modelData: IModelTemplateData;
};

const cellStyles =
  '!bg-hi-2 !font-inter !p-4 !text-base !font-normal !text-black !text-left';
const cellWithBoldStyles =
  '!bg-hi-2 !font-inter !p-4 border !text-base !font-medium !text-black !text-left';

const ScrRow = ({
  first4Cells,
  score,
  resLength,
}: {
  first4Cells: string[];
  score: number;
  resLength: number;
}) => (
  <tr className={`!border-l`}>
    <td className={`${cellWithBoldStyles} border-none`}>{first4Cells[0]}</td>
    <td className={`${cellStyles} border-l border-none`}>{first4Cells[1]}</td>
    <td className={`${cellStyles} border-none`}>{first4Cells[2]}</td>
    <td className={`${cellStyles}`}>{first4Cells[3]}</td>
    <td className={cellStyles}>
      <div className="flex flex-row items-center gap-2">
        {score.toFixed(2)}{' '}
        <div className="h-3 w-20 !bg-white">
          <div
            style={{
              width: `${score * 100}%`,
            }}
            className={`h-full bg-hi-60`}
          />
        </div>
      </div>
    </td>
    <td className={cellStyles}>{resLength}</td>
  </tr>
);

const ProgressBar: React.FC<{ score: number }> = ({ score }) => {
  if (Number.isNaN(score)) return null;
  return (
    <div className="h-3 w-20 !bg-white">
      <div
        style={{
          width: `${score * 100}%`,
        }}
        className={`h-full bg-hi-60`}
      />
    </div>
  );
};

const McrLcrRow = ({
  first4Cells,
  score,
  resLength,
}: {
  first4Cells: string[];
  score: number;
  resLength: number;
}) => {
  if (!first4Cells[1]) return null;

  return (
    <tr>
      <td className={cellWithBoldStyles}>{first4Cells[0]}</td>
      <td className={cellStyles}>{first4Cells[1]}</td>
      <td className={cellStyles}>{first4Cells[2]}</td>
      <td className={cellStyles}>{first4Cells[3]}</td>
      <td className={cellStyles}>
        <div className="flex flex-row items-center gap-2">
          {score.toFixed(2)} <ProgressBar score={score} />
        </div>
      </td>
      <td className={cellStyles}>{resLength}</td>
    </tr>
  );
};

const PerformanceSummary = ({ modelData }: Props) => {
  return (
    <section className="mx-auto w-full max-w-[90rem] lg:px-[85px]">
      <div className="flex flex-col gap-6 border border-b-0 border-gray-400/40 px-5 py-8 lg:py-10">
        <h3 className="mb-5 font-inter text-2xl font-bold lg:text-[32px]">
          Performance Summary
        </h3>

        <div className="overflow-x-auto">
          <table className="table-aut w-full">
            <thead>
              <tr>
                <th className={cellWithBoldStyles}>Tasks</th>
                <th className={cellWithBoldStyles}>Task insight</th>
                <th className={cellWithBoldStyles}>Cost insight</th>
                <th className={cellWithBoldStyles}>Dataset</th>
                <th className={cellWithBoldStyles}>Context adherence</th>
                <th className={cellWithBoldStyles}>Avg response length</th>
              </tr>
            </thead>
            <tbody className={`border border-[rgba(49,23,114,0.15)]`}>
              {/* Short Context */}
              <ScrRow
                first4Cells={[
                  'Short context RAG',
                  modelData.sTaskIns,
                  modelData.sCostIns,
                  'Drop',
                ]}
                score={modelData.drop}
                resLength={modelData.resLen}
              />
              <ScrRow
                first4Cells={['', '', '', 'Hotpot']}
                score={modelData.hotpot_qa}
                resLength={modelData.resLen}
              />
              <ScrRow
                first4Cells={['', '', '', 'MS Marco']}
                score={modelData.ms_marco}
                resLength={modelData.resLen}
              />
              <ScrRow
                first4Cells={['', '', '', 'ConvFinQA']}
                score={modelData.convfinqa}
                resLength={modelData.resLen}
              />
              {/* Medium Context */}
              <McrLcrRow
                first4Cells={[
                  'Medium context RAG',
                  modelData.mTaskIns,
                  modelData.mCostIns,
                  'Medium context RAG',
                ]}
                score={modelData.mcRag}
                resLength={modelData.resLen}
              />
              {/* Long Context */}
              <McrLcrRow
                first4Cells={[
                  'Long context RAG',
                  modelData.lTaskIns,
                  modelData.lCostIns,
                  'Long context RAG',
                ]}
                score={modelData.lcRag}
                resLength={modelData.resLen}
              />
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default PerformanceSummary;
