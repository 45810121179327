import React from 'react';
import { graphql } from 'gatsby';

import MarketingLayout from '@/components/common/hl-marketing-layout';
import Hero from '@/components/hallucination-index-2024/model-page/hero';
import ModelPerformance from '@/components/hallucination-index-2024/model-page/model-performance';
import ScRag from '@/components/hallucination-index-2024/model-page/sc-rag';
import McLcRag from '@/components/hallucination-index-2024/model-page/mc-lc-rag';
import PerformanceSummary from '@/components/hallucination-index-2024/model-page/performance-summary';
import PreFooter from '@/components/hallucination-index-2024/pre-footer';

import {
  formHiModelsData,
  checkIfModelHasLcrImage,
  checkIfModelHasMcrImage,
} from '@/components/hallucination-index-2024/model-data-helpers';
import { IHiModel } from '@/components/hallucination-index-2024/model-data-helpers';

export interface IModelTemplateData extends IHiModel {
  ms_marco: number;
  drop: number;
  convfinqa: number;
  hotpot_qa: number;
  scRag: number;
  mcRag: number;
  lcRag: number;
  resLen: number;
  sCostIns?: string;
  sTaskIns?: string;
}

const ModelTemplatePage = ({ data }) => {
  const { model, scr, mcr, lcr } = data;
  const formattedModelData = formHiModelsData(model)[0];
  const modelData = {
    ...formattedModelData,
    ms_marco: parseFloat(scr.nodes[0]?.ms_marco),
    drop: parseFloat(scr.nodes[0]?.drop),
    convfinqa: parseFloat(scr.nodes[0]?.convfinqa),
    hotpot_qa: parseFloat(scr.nodes[0]?.hotpot_qa),
    scRag: parseFloat(scr.nodes[0]?.model_avg),
    mcRag: parseFloat(mcr.nodes[0]?.model_avg),
    lcRag: parseFloat(lcr.nodes[0]?.model_avg),
    resLen: parseFloat(scr.nodes[0]?.avg_response_len),
    sTaskIns: scr.nodes[0]?.Task_insight,
    sCostIns: scr.nodes[0]?.Cost_insight,
    mTaskIns: mcr.nodes[0]?.Task_insight,
    mCostIns: mcr.nodes[0]?.Cost_insight,
    lTaskIns: lcr.nodes[0]?.Task_insight,
    lCostIns: lcr.nodes[0]?.Cost_insight,
  } as IModelTemplateData;
  return (
    <MarketingLayout>
      <div className="bg-smoky-black">
        <Hero modelData={modelData} />
      </div>
      <ModelPerformance modelData={modelData} />
      <ScRag modelData={modelData} />
      {checkIfModelHasMcrImage(modelData.name) && (
        <McLcRag modelData={modelData} type="mcr" title="Medium Context RAG" />
      )}
      {checkIfModelHasLcrImage(modelData.name) && (
        <McLcRag modelData={modelData} type="lcr" title="Long Context RAG" />
      )}
      <PerformanceSummary modelData={modelData} />
      <PreFooter />
    </MarketingLayout>
  );
};

export const query = graphql`
  query ModelTemplate($modelName: String!) {
    model: allHiModelsCsv(filter: { Model: { eq: $modelName } }) {
      nodes {
        Model
        Developer
        Model_parameters
        License
        Open_Closed
        Supported_context_length
        _xMxpromptxtokens
        _xMxresponsextokens
      }
    }

    scr: allScrCsv(filter: { Model: { eq: $modelName } }) {
      nodes {
        Model
        model_avg
        drop
        hotpot_qa
        convfinqa
        ms_marco
        avg_response_len
        Task_insight
        Cost_insight
      }
    }
    mcr: allMcrCsv(filter: { Model: { eq: $modelName } }) {
      nodes {
        Model
        model_avg
        Task_insight
        Cost_insight
      }
    }
    lcr: allLcrCsv(filter: { Model: { eq: $modelName } }) {
      nodes {
        Model
        model_avg
        Task_insight
        Cost_insight
      }
    }
  }
`;

export default ModelTemplatePage;
